import { ColDef, NewValueParams, ValueGetterParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { PrecisionPipe } from '@supy/common';
import { GridPocComponent, InputCellEditorComponent } from '@supy/components';
import { RetailerSupplierItem } from '@supy/retailers';

@Component({
  selector: 'supy-retailer-supplier-items-grid',
  templateUrl: './retailer-supplier-items-grid.component.html',
  styleUrls: ['./retailer-supplier-items-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerSupplierItemsGridComponent implements OnChanges {
  @ViewChild(GridPocComponent, { static: false }) protected readonly grid: GridPocComponent<RetailerSupplierItem>;

  @Input() readonly currency: Currency = Currency.UnitedArabEmiratesDirham;
  @Input() readonly currencyPrecision: number;
  @Input() readonly isPriceEditable: boolean;
  @Input() readonly isPriceHidden: boolean;
  @Input() readonly requestMetadata: { page: number; limit: number };
  @Input() readonly responseMetadata: { total: number; count: number };
  @Input() readonly isLoading: boolean;

  @Input() set data(data: RetailerSupplierItem[] | null) {
    if (data) {
      this.supplierItems.set(structuredClone(data ?? []));
    }
  }

  @Output() readonly rowSelected = new EventEmitter<string[]>();
  @Output() readonly pageChange = new EventEmitter<number>();

  protected readonly supplierItems = signal<RetailerSupplierItem[]>([]);
  protected readonly columnDefs = signal<ColDef<RetailerSupplierItem>[]>([]);
  protected readonly defaultColDefs = signal<Partial<ColDef<RetailerSupplierItem>>>({
    cellStyle: { border: 'none' },
    suppressHeaderKeyboardEvent: () => true,
  });

  readonly #precision = inject(PrecisionPipe);

  get rows(): RetailerSupplierItem[] {
    return this.grid ? this.grid.data : [];
  }

  get selectedRows(): RetailerSupplierItem[] {
    return this.grid ? this.grid.api?.getSelectedRows() : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currency'] || changes['currencyPrecision'] || changes['isPriceEditable'] || changes['isPriceHidden']) {
      this.setColumnDefs();
    }
  }

  protected setColumnDefs(): void {
    this.columnDefs.set([
      {
        width: 50,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: $localize`:@@common.itemCode:Item Code`,
        field: 'retailerItem.code',
        flex: 0.5,
      },
      {
        headerName: $localize`:@@common.itemName:Item Name`,
        field: 'name.en',
        flex: 1.5,
      },
      {
        headerName: $localize`:@@categoryItem:Item Category`,
        field: 'category.name.en',
        flex: 1,
      },
      {
        headerName: $localize`:@@common.itemSubcategory:Item Subcategory`,
        field: 'subCategory.name.en',
        flex: 1,
      },
      {
        headerName: $localize`:@@supplier_item_name:Supplier Item Name`,
        field: 'supplierItem.name.en',
        flex: 2,
      },
      {
        headerName: $localize`:@@supplier_item_code:Supplier Item Code`,
        field: 'itemCode',
        flex: 1,
      },
      {
        headerName: $localize`:@@grid.headers.currentPriceCurrency.label:Current Price (${this.currency})`,
        hide: this.isPriceHidden,
        field: 'price',
        valueGetter: (params: ValueGetterParams<RetailerSupplierItem, number>) =>
          this.#precision.transform(params.data?.price as number, this.currencyPrecision),
        editable: this.isPriceEditable,
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            placeholder: '0.00',
            precision: this.currencyPrecision,
          },
        },

        onCellValueChanged: (event: NewValueParams<RetailerSupplierItem, number>) => this.handleUpdatePrice(event),
        flex: 0.8,
      },
      {
        headerName: $localize`:@@common.pricingUom:Pricing UOM`,
        field: 'supplierItem.invoiceUnit.name',
        flex: 0.7,
      },
      {
        headerName: $localize`:@@grid.headers.pricingPerPackageUom.label:Pricing Per Package UOM (${this.currency})`,
        valueGetter: (params: ValueGetterParams<RetailerSupplierItem>) =>
          this.#precision.transform(
            this.calculateTotalPrice(params.data as RetailerSupplierItem),
            this.currencyPrecision,
          ),

        flex: 1.2,
      },
    ]);
  }

  protected onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  private handleUpdatePrice(event: NewValueParams<RetailerSupplierItem>): void {
    const updatedData = this.supplierItems().map(item => {
      if (item.id === event.data.id) {
        return { ...item, price: +event.newValue };
      }

      return item;
    });

    this.supplierItems.set(updatedData);
  }

  private calculateTotalPrice(supplierItems: RetailerSupplierItem): number {
    if (
      supplierItems &&
      supplierItems.price &&
      Number(supplierItems.packaging?.baseUnit?.conversionToAtom) &&
      Number(supplierItems.supplierItem?.invoiceUnit?.conversionToAtom)
    ) {
      return (
        (supplierItems.price *
          (supplierItems.packaging?.baseUnit?.conversionToAtom ?? 0) *
          supplierItems.packaging?.quantity?.baseUnit) /
        supplierItems.supplierItem?.invoiceUnit?.conversionToAtom
      );
    }

    return 0;
  }

  clearSelection(): void {
    this.grid.clearSelection();
  }
}
