import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable, getAffectedRows, SupplierMetadata, SupplierPartnershipTypeEnum } from '@supy/common';
import { DialogService, FilterChange, FilterGroup } from '@supy/components';
import { RetailerSupplierItem, UpdateRetailerSupplierItemsRequest } from '@supy/retailers';

import { RetailerSupplierItemsGridComponent } from '../retailer-supplier-items-grid';

@Component({
  selector: 'supy-retailer-supplier-items',
  templateUrl: './retailer-supplier-items.component.html',
  styleUrls: ['./retailer-supplier-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class RetailerSupplierItemsComponent extends Destroyable {
  @ViewChild(RetailerSupplierItemsGridComponent) private readonly supplierItemsGrid: RetailerSupplierItemsGridComponent;

  @Input() readonly retailerId: string;
  @Input() readonly isLoading: boolean;
  @Input() readonly currencyPrecision: number;
  @Input() readonly currency: Currency;
  @Input() readonly integratedSupplierInfo?: SupplierMetadata & { readonly name: string };
  @Input() readonly isPriceHidden: boolean;
  @Input() readonly requestMetadata: { page: number; limit: number };
  @Input() readonly responseMetadata: { total: number; count: number };
  @Input() readonly isCKSupplierV1: boolean;
  @Input() readonly isCKSupplierV2: boolean;
  @Input() readonly filtersGroup: FilterGroup<FilterChange>;

  @Input() set data(supplierItems: RetailerSupplierItem[]) {
    if (supplierItems) {
      this.supplierItems.set(structuredClone(supplierItems ?? []));
    }
  }

  @Output() readonly updateRetailerSupplierItems = new EventEmitter<UpdateRetailerSupplierItemsRequest>();
  @Output() readonly deleteRetailerSupplierItems = new EventEmitter<{
    deleteRequest: string[];
    isDataChanged?: boolean;
    selectedItemIds: string[];
  }>();

  @Output() readonly patchFilters = new EventEmitter<FilterChange>();
  @Output() readonly resetFilters = new EventEmitter<void>();

  @Output() readonly pageChange = new EventEmitter<number>();

  @Output() readonly exportSelected = new EventEmitter<string[]>();
  @Output() readonly exportAll = new EventEmitter<void>();

  protected readonly supplierItems = signal<RetailerSupplierItem[]>([]);

  get affectedRows(): RetailerSupplierItem[] {
    return getAffectedRows({
      originalList: this.supplierItems() ?? [],
      updatedList: this.supplierItemsGrid?.rows ?? [],
      keys: ['price'],
    });
  }

  get isSaveDisabled(): boolean {
    return this.affectedRows.length === 0;
  }

  get isIntegratedSupplier(): boolean {
    return this.integratedSupplierInfo?.partnershipType === SupplierPartnershipTypeEnum.Integrated;
  }

  get integratedSupplierContact(): string | undefined {
    return this.integratedSupplierInfo?.partnershipContact;
  }

  get supplierName(): string | undefined {
    return this.integratedSupplierInfo?.name;
  }

  get selectedRows(): RetailerSupplierItem[] {
    return this.supplierItemsGrid?.selectedRows ?? [];
  }

  get selectedRowsIds(): string[] {
    return this.supplierItemsGrid?.selectedRows.map(item => item.id);
  }

  get isSelectedRows(): boolean {
    return this.selectedRows.length > 0;
  }

  protected onApplyFilter({ search, ...rest }: FilterChange): void {
    const filters = {
      codeName: search,
      ...rest,
    };

    this.patchFilters.emit(filters);
  }

  protected onUpdate(): void {
    const itemsRequest: UpdateRetailerSupplierItemsRequest = {
      items: this.affectedRows.map(item => ({
        channelItemsIds: item.channelItems.map(channelItem => channelItem.id),
        price: +item.price,
      })),
    };

    this.updateRetailerSupplierItems.emit(itemsRequest);
  }

  protected onDelete(): void {
    const deleteRequest: string[] = this.selectedRows.flatMap(item =>
      item.channelItems.map(channelItem => channelItem.id),
    );

    this.deleteRetailerSupplierItems.emit({
      deleteRequest,
      isDataChanged: this.affectedRows.length > 0,
      selectedItemIds: this.selectedRowsIds,
    });

    this.supplierItemsGrid.clearSelection();
  }

  protected onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  protected onExportSelected(): void {
    this.exportSelected.emit(this.selectedRowsIds);
  }

  protected onExportAll(): void {
    this.exportAll.emit();
  }
}
