<div class="supy-retailer-supplier-items-grid">
  <supy-loading-overlay *ngIf="isLoading"></supy-loading-overlay>

  <supy-grid-poc
    class="supy-retailer-supplier-items-grid__supy-grid"
    [data]="supplierItems()"
    [columnDefs]="columnDefs()"
    (gridReady)="setColumnDefs()"
    [defaultColDefs]="defaultColDefs()"
    height="100%"
    domLayout="normal"
    rowSelection="multiple"
    [isPaginated]="true"
    [pageIndex]="requestMetadata.page"
    [perPage]="requestMetadata.limit"
    [prevPageDisabled]="requestMetadata.page === 0"
    [nextPageDisabled]="responseMetadata.count < requestMetadata.limit"
    [disableSelectOnClick]="true"
    (pageChange)="onPageChange($event)"
  ></supy-grid-poc>
</div>
